import React, { useEffect, useRef, useState } from 'react';
interface Props {
    projectIndex: number;
    title: string;
    backgroundImage: string;
    children: string;
    showcaseClicked: (index:number) => void;
}

function ProjectShowcase(props: Props)
{          
    const [mouseOver, setMouseOver] = useState(false)
    const titleRef = useRef<HTMLElement>(null)
    const blurbRef = useRef<HTMLElement>(null)

    useEffect(() => {
        
        if (mouseOver)
        {
            blurbRef.current!.style.opacity = "1";
            blurbRef.current!.style.height = "55%";
            
            titleRef.current!.style.top = "25%";
            blurbRef.current!.style.top = "65%";
        }
        else
        {
            blurbRef.current!.style.opacity = "0";
            blurbRef.current!.style.height = "0%";

            titleRef.current!.style.top = "50%";
            blurbRef.current!.style.top = "100%";
        }
    }, [mouseOver])
    return (
        <div className='showcase-container'
        style={{WebkitMaskImage: `url(clouds/img${(props.projectIndex)%5}.png)`, maskImage: `url(clouds/img${(props.projectIndex)%5}.png)`}}
        >
            <div className='showcase-clip'>

                <div className="project-showcase"
                style={{backgroundImage : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url(Projects/${props.backgroundImage}.png)`, 
                // WebkitMaskImage: `url(clouds/img${props.projectIndex+1}.png)`, maskImage: `url(clouds/img${props.projectIndex+1}.png)`
                }} 
                onMouseEnter={() => { setMouseOver(true) }} onMouseLeave={() => {setMouseOver(false)}}
                onClick={ () => {props.showcaseClicked(props.projectIndex)}}>

                    <h1 className="" ref={titleRef as React.RefObject<HTMLDivElement>}>{props.title}</h1>
                    <p ref={blurbRef as React.RefObject<HTMLDivElement>}>{props.children}</p>

                </div> 

            </div>
        </div>
    );
}

export default ProjectShowcase;