import React, { useEffect, useRef, useState } from 'react';
import ProjectShowcase from './ProjectShowcase';
import ProjectPopup from './ProjectPopup';

type project = {
    title: string;
    blurb: string;
    resourcesName: string;
    link?: string;
}
interface Props 
{
    projects: project[];
}


function ProjectGallery(props : Props) 
{
    const [isPopupOpen, setPopupOpen] = useState(false)
    const [clickedIndex, seetClickedIndex] = useState(0)    

    const showcaseClicked = (index: number) => {
        seetClickedIndex(index)
        setPopupOpen(true)
    }

    
    let allShowcases : React.JSX.Element[] = [];
    props.projects.forEach((p:project, i:number) => {   
        allShowcases.push(
            <ProjectShowcase
            backgroundImage = {p.resourcesName}
            projectIndex={i}
            title={p.title}
            key={props.projects.indexOf(p)}
            showcaseClicked={showcaseClicked}>{p.blurb}</ProjectShowcase>
        );
    });

    // React.useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
            
    //     });
    //     if (domRef.current != null)
    //     {
    //         observer.observe(domRef.current);
    //     }
    //     return () => {if (domRef.current != null) {observer.unobserve(domRef.current) }} ;
    // }, []);

    return (<>
        <ProjectPopup isOpen={isPopupOpen} onClose={() => {setPopupOpen(false)}} selected={clickedIndex} projects={props.projects}/>
        <div style={{ position : "relative"}}>
            <div id="project-gallery">
                {allShowcases}
            </div>
        </div>
    </>
    );
}   

export default ProjectGallery;