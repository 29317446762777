import React, { useCallback, useEffect, useRef, useState } from "react"
import Popup from "reactjs-popup"
import Header from "./Header"
import Markdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { PopupActions } from "reactjs-popup/dist/types"

type project = {
    title: string;
    blurb: string;
    resourcesName: string;
    link?: string;
}

interface Props
{
    isOpen: boolean;
    onClose: () => void;

    selected: number;
    projects: project[];
}

function ProjectPopup(props: Props)
{
    const [writeups, setWriteups] = useState<{[index: string]: string}>({});    
    const popupRef = useRef<HTMLDivElement>(null);
    useEffect(() => {   
        var newWriteup: {[index: string]: string} = {};  
        props.projects.forEach((p, i, arr) => {                    
            const file = require(`../data/writeups/${p.resourcesName}.md`);            
            fetch(file)
            .then(response => response.text())
            .then((text: string) => {
                newWriteup[p.resourcesName] = text;                
                setWriteups(newWriteup)
            })
        });
    }, []);

    let title = props.projects[props.selected].title;
    let writeup =writeups[props.projects[props.selected].resourcesName];

    let popup = <Popup open={props.isOpen} closeOnDocumentClick onClose={props.onClose} modal onOpen={() => {popupRef.current?.scrollTo(0, 0);}}>
        <div id="project-popup" ref={popupRef}>
            <Header style={{color : "black", marginBottom: "0.5em"}}>{title}</Header>
            <Markdown className="shifted-right" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {writeup}
            </Markdown>
            <button onClick={props.onClose}>&times;</button>
        </div>
    </Popup>
    
    
    return popup;
}
export default ProjectPopup;